import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import propTypes from 'prop-types';
import { NavLink, redirect } from "react-router-dom";
import { useKeycloak } from "../context/keycloakContext";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export default function AvatarMenu({ settings }) {
  const { kc }= useKeycloak();

  const { t: tCommon } = useTranslation('app');

  const [userMenu, setUserMenu] = useState(null)

  const handleOpenUserMenu = (event) => {
    setUserMenu(event.currentTarget);
  }

  const handleCloseUserMenu = () => {
    setUserMenu(null);
  }
  
  const handleLogout = () => {
    // Implement logout functionality
    kc.logout();
    redirect("/");
  }

  return (
    <Box
      sx={{
        margin: '10px',
      }}
    >
      <Tooltip title="settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            sx={{
              width: '30',
              height: '30',
              marginRight: '10px'
            }}
          >
            <img
              src="https://www.w3schools.com/howto/img_avatar.png"
              alt="avatar"
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
          sx={{
            mt: '45px',
            '& .MuiPaper-root': {
              backgroundColor: '#19153b',
              color: 'white',
              border : '1px solid black',
            }
          }}
          id="menu-appbar"
          anchorEl={userMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(userMenu)}
          onClose={handleCloseUserMenu}
        >
          {
            settings.map((setting) => (
              <NavLink key={setting} to={setting.route} style={{textDecoration: 'none', color: 'white'}}>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{tCommon(setting.text)}</Typography>
                </MenuItem>
              </NavLink>
            ))
          }
            <MenuItem onClick={handleLogout}>
              <Typography sx={{ textAlign: 'center' }}>{tCommon('logout')}</Typography>
            </MenuItem>
        </Menu>
    </Box>
  );
}

AvatarMenu.propTypes = {
  settings: propTypes.array.isRequired,
}
