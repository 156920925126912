import { useRef, useState } from 'react'
import FileInputButton from '../../components/FileInputButton';
import CustomPlayer from '../../Player/Player';
import SnackBarAlert from '../../components/SnackBarAlert';
import UploadForm from './UploadForm';
import { UploadPaper } from '../../components/CustomComponents';
import Button from '../../components/Button';
import { HighlightOff, Upload } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Api } from '../../services/Api';
import { useKeycloak } from '../../context/keycloakContext';
import { transmit } from '../../services/Transmit';
import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';

const AcceptedVideoTypes = [
  'video/mp4',
  'video/webm',
]

export default function UploadPage() {
  const { t } = useTranslation("UploadPage")
  const { user } = useKeycloak()

  const uploadFormRef = useRef({}) // Upload Form reference

  // Snack State for alert messages
  const [snackState, setSnackState] = useState({
    open: false,
    message: '',
    severity: 'info',
  })

  const onClosedSnack = () => {
    setSnackState({
      open: false,
      message: snackState.message,
      severity: snackState.severity,
    })
  }

  const [videoSrc , setVideoSrc] = useState(null)
  const [videoFile, setVideoFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleChange = (file) => {
    // Check if the file has accepted video type
    if (AcceptedVideoTypes.includes(file.type)) {
      const url = URL.createObjectURL(file)
      setVideoSrc(url)
      setVideoFile(file)
    } else {
      setSnackState({
        open: true,
        message: t("Invalid file type"),
        severity: "error"
      })
      console.error("Invalid file type")
    }
  }

  const uploadVideo = async () => {
    setUploading(true)
    setProgress(0)
    setVideoSrc(null)

    if (videoFile != null) {
      // check if the form fields are filled
      if (!uploadFormRef.current.verifyFields()) {
        setUploading(false)
        setSnackState({
          open: true,
          message: t("form not filled"),
          severity: "error"
        })
        return
      }

      const formData = new FormData()
      formData.append("video", videoFile)
      formData.append("title", uploadFormRef.current.title)
      formData.append("gameLicense", uploadFormRef.current.gameLicense)
      formData.append("description", uploadFormRef.current.description)

      // subscribe to the upload progress
      const progressChannel = transmit.subscription(`upload/progress/${user.sub.replace(/-/g, "")}`)
      await progressChannel.create()

      // listen to the progress channel
      progressChannel.onMessage((progress) => {
        setProgress(progress)
        if (progress >= 100) {
          progressChannel.delete()
          setUploading(false)
          setSnackState({
            open: true,
            message: t("Upload success"),
            severity: "success"
          })
          uploadFormRef.current.resetStates()
        }
      })
      
      await Api.uploadVideo(formData)
        .catch((error) => {
          console.error(error)
          setSnackState({
            open: true,
            message: t("upload error"),
            severity: "error"
          })
          setUploading(false)
          setProgress(0)
        })
    }
  }

  return (
    <>
      {/* ALERT MESSAGES */}
      <SnackBarAlert
        open={snackState.open}
        message={snackState.message}
        severity={snackState.severity}
        onClose={onClosedSnack}
      />
      {/* UPLOAD PAGE */}
      <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '20px',
          boxShadow: '0 20px 60px rgba(0, 0, 0, 0.3)',
          fontFamily: 'Exo, sans-serif',
          p: 4,
        }}
      >
        {/* TITLE */}
        <Typography variant="h4">
          {t("upload title")}
        </Typography>

        {/* UPLOAD FORM */}
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UploadForm
            ref={uploadFormRef}
          />
        </Box>

        {/* VIDEO PLAYER */}
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UploadPaper
            elevation={6}
          >
            {
              (() => {
                if (videoSrc != null) {
                  return (
                    <CustomPlayer
                      layoutType={"fullControls"}
                      src={videoSrc}
                    />
                  );
                } else if (uploading === true) {
                  return (
                    <>
                      <Box
                        sx={{
                          width: '80%',
                        }}
                      >
                        <LinearProgress
                          variant="determinate" value={progress}
                          sx={{
                            height: '10px',
                            borderRadius: 5,
                            [`& .${linearProgressClasses.bar}`]: {
                              borderRadius: 5,
                              backgroundColor: '#e91e63',
                            },
                          }}
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'white',
                          ml: 2,
                        }}
                      >
                        {`${progress}%`}
                      </Typography>
                    </>
                  );
                } else {
                  return (
                    <FileInputButton onChange={handleChange}>
                      {t("search file")}
                    </FileInputButton>
                  );
                }
              })()
            }
          </UploadPaper>
        </Box>

        {/* BUTTONS */}
        <Box sx={{
            mt: 4,
            display: 'flex',
            gap: '20px',
            justifyContent: 'flex-end',
          }}
        >
          {/* Deselect file */}
          <Button
            disabled={videoSrc == null}
            type={"secondary"}
            icon={<HighlightOff />}
            onClick={() => {
              setVideoFile(null)
              setVideoSrc(null)
            }}
          >
            {t("cancel")}
          </Button>

          {/* Upload file */}
          <Button
            disabled={videoSrc == null}
            onClick={uploadVideo}
            icon={<Upload />}
          >
            {t("upload video")}
          </Button>
        </Box>
      </Box>
    </>
  )
}
