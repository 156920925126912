import {
  ListSubheader,
  Paper,
  Select,
  styled,
  TextField,
  Button,
  ListItemButton
} from "@mui/material"

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: "white",
    backgroundColor: "transparent",
    // Class for the border around the input field
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5740fe",
      borderWidth: "2px",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "violet",
        borderWidth: "2px",
      },
    },
    "&:hover:not(.Mui-focused)": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
    },
  },
  '& .MuiInputLabel-outlined': {
    color: 'grey !important',
    fontWeight: 'bold',
    "&.Mui-focused": {
      color: "white",
      fontWeight: "bold",
    },
  },
})

export const CustomSelect = styled(Select)({
  color: "white",
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "#5740fe",
    borderWidth: "2px",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "violet",
      borderWidth: "2px",
    },
  },
  "&:hover:not(.Mui-focused)": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  '& label.Mui-focused': {
    color: 'green', // Couleur du label lorsque le champ est en focus
  },
})

export const SideBarPaper = styled(Paper)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  background: 'linear-gradient(135deg, #201c47 0%, #19153b 50%)',
  width: '15%',
  '& .SideBar-Header': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    fontStyle: "italic",
  }
}))

export const CustomListSubheader = styled(ListSubheader)(({ theme }) => ({
  fontStyle: 'italic',
  background: 'transparent',
  color: 'grey',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export const UploadPaper = styled(Paper)(({ theme }) => ({
  width: '90%',
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #201c47 0%, #19153b 50%)',
  color: theme.palette.primary.contrastText,
  padding: 0,
}))

export const SearchButton = styled(Button)({
  color: 'white',
  backgroundColor: 'transparent',
  border: '1px solid #800080',
})

export const SearchListButton = styled(ListItemButton)({
  color: 'white',
  borderRadius: 15,
  border: '1px solid #5740fe',
  '&:hover': {
    backgroundColor: 'rgba(128, 0, 128, 0.3)',
    border: '1px solid #800080',
  }
})
