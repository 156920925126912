import { Navigate } from "react-router-dom";
import { useKeycloak } from "../context/keycloakContext";

const Logout = ({ children }) => {
  const kc = useKeycloak();

  if (kc.authenticated === true) {
    kc.logout(
      {
        redirectUri: window.location.origin
      }
    );
  }

  return <Navigate
      to={'/'}
      replace={true}
    />
};

export default Logout;
