import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';

export default function SideBarItem (props) {
  const {
    Icon,
    text,
    route
  } = props;

  return (
    <NavLink to={route} style={{textDecoration: 'none', color: 'white'}}>
      <ListItemButton
        sx={{
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: 'white',
          borderTop: '1px solid transparent',
          borderBottom: '1px solid transparent',

          background: 'radial-gradient(circle, #827f7f 0%, transparent 80%) top, radial-gradient(circle, #827f7f 0%, transparent 80%) bottom',
          
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 1px',

          "&:hover, :focus": {
            borderTop: '1px solid transparent !important',
            borderBottom: '1px solid transparent !important',
            background: 'radial-gradient(ellipse at center -50%, rgba(173,83,137,0.5) 0%, transparent 60%)',
          },
        }}
      >
        <ListItemIcon>
          <Icon
            sx={{
              color: 'white'
            }}
          />
        </ListItemIcon>
        <ListItemText primary={text}>
          
        </ListItemText>
      </ListItemButton>
    </NavLink>
  );
}

SideBarItem.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
};
