import { Button as MuiButton } from "@mui/material";
import PropTypes from 'prop-types';

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default function Button({ children, onClick, type, icon, disabled, loading }) {
  const buttonTypes = [
    'primary',
    'secondary',
    'success',
    'warning',
    'error',
    'info',
    'default'
  ];

  const isValidType = buttonTypes.includes(type);

  if (!isValidType) {
    type = 'default';
  }

  const color = {
    primary: '#e91e63',
    secondary: '#5740fe',
    success: '#4caf50',
    warning: '#ff9800',
    error: '#f44336',
    info: '#2196f3',
    default: '#f50057',
  }[type];

   const disabledColor = {
    primary: '#603040',
    secondary: '#5740fe',
    success: '#4caf50',
    warning: '#ff9800',
    error: '#f44336',
    info: '#2196f3',
    default: '#603040',
   }[type];

  if (loading) {
    disabled = true;
  }

  return (
    <MuiButton
      disabled={disabled}
      loading={loading}
      startIcon={icon}
      variant="contained"
      onClick={onClick}
      sx={{
        margin: '0 10px',
        backgroundColor: color,
        '&:hover': {
          backgroundColor: '#f73378',
        },
        '&:disabled': {
          backgroundColor : disabledColor,
        }
      }}
    >
      {children}
    </MuiButton>
  );
}
