import { axiosInstance } from "./axios"

export const Api = {
  getFilVideos: async () => {
    const data = await axiosInstance.get("/videos")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting videos", error)
        return []
      })
    return data
  },
  uploadVideo: async (formData) => {
    const response = (await axiosInstance.post("/upload",
      formData
      , {
        headers: {
          "Content-Type": "multipart/form-data;",
        }
      }
    ))
    return response.data
  },
  getGameLicenses: async () => {
    const data = await axiosInstance.get("/game-licenses")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting game licenses", error)
        return {}
      })
    return data
  },
  getUltra: async () => {
    const response = await axiosInstance.get("/ultra") // appel controller ultra 
    return response
  },
  getVersus: async () => {
    const data = await axiosInstance.get("/versus")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting versus videos", error)
        return []
      })
    return data 
  },
  getRandomVideos: async () => {
    const data = await axiosInstance.get("/random-videos")
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting random videos", error)
        return []
      })
    return data
  },
  // "getMultipleRandomVideos" méthode prend un paramètre pairCount qui indique le nombre de paires de vidéos
  // à récupérer. ca va effectue des appels répétés à getRandomVideos 
  // et accumule les paires dans un tableau videoPairs
  getMultipleRandomVideos: async (pairCount) => {
    const videoPairs = []
    for (let i = 0; i < pairCount; i++) {
      const data = await Api.getRandomVideos()
      if (data.videos && data.videos.length === 2) {
        videoPairs.push(data.videos)
      }
    }
    return videoPairs
  },
  /**
   * getUserHighlights
   * 
   * @param string userId 
   * @returns 
   */
  getUserHighlights: async (userId) => {
    const data = await axiosInstance.get(`/user-videos/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting user videos", error)
        return []
      })
    return data
  },

  /*********** USER requests *************/

  /**
   * getUserProfile
   * 
   * @param string userId 
   * @returns 
   */
  getUserProfile: async (userId) => {
    const data = await axiosInstance.get(`/user-profile/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error getting user profile", error)
        return null
      })
    return data
  },
  searchUsers: async (searchTerm) => {
    const data = await axiosInstance.get(`/search-users/${searchTerm}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error searching users", error)
        return []
      })
    return data
  },
}
