import React from 'react';
import PropTypes from 'prop-types';
import CustomPlayer from '../../Player/Player';
import { Box, Typography } from '@mui/material';
import styles from './SingleHighlight.module.css';
import { useTranslation } from "react-i18next";

export default function SingleHighlight({ video }) {
  const { t } = useTranslation('app');

  return (
    <Box className={styles.singleHighlight}>
      <Box className={styles.videoContainer}>
        <CustomPlayer layoutType="minimalControls" src={video.url} />
        <Box className={styles.highlightMeta}>
          <Typography variant="h6" fontWeight="bold">{video.title}</Typography>
          <Typography variant="body1">{t('Uploader')}: {video.uploader}</Typography>
          <Typography variant="body1">{t('Jeu')}: {video.game}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

SingleHighlight.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uploader: PropTypes.string.isRequired,
    game: PropTypes.string.isRequired,
  }).isRequired,
};
