import React, { useEffect, useState } from 'react';
import { useKeycloak } from "../../context/keycloakContext";
import { useTranslation } from "react-i18next";
import { Box, Typography, Avatar, Paper, Divider, Grid2, styled } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SingleHighlight from '../../components/Feed/SingleHighlight';
import { Api } from '../../services/Api';
import { Navigate, useParams } from "react-router-dom"
import { use } from 'i18next';

// Styles Paper stats
const StatsPaper = styled(Paper)(({ theme }) => ({
    height: '100%',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#2a2a3e',
    color: 'white',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 5px 15px rgba(128, 0, 128, 0.5)',
    },
}));

export function ProfilPage() {
    const { username } = useParams();
    const { isAuthenticated, user } = useKeycloak();
    const { t } = useTranslation('app');

    // states for user stats and highlights
    const [userProfile, setUserProfile] = useState({});
    const [userHighlights, setUserHighlights] = useState([]);
    const [errorLoadingProfile, setErrorLoadingProfile] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            await Api.getUserProfile(username)
                .then((data) => {
                    if (data === null) {
                        setErrorLoadingProfile(true)
                    }
                    setUserProfile(data)
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des statistiques de l\'utilisateur', error)
                    setErrorLoadingProfile(true)
                });
        }
        fetchProfile();
    }, [username]);

    useEffect(() => {
        const fetchHighlights = async () => {
            await Api.getUserHighlights(userProfile?.id)
                .then((data) => {
                    setUserHighlights(data)
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des highlights de l\'utilisateur', error)
                });
        }
        fetchHighlights();
    }, [userProfile])

    if (errorLoadingProfile === true) {
        return (
            <Navigate to="/404" replace={true} />
        )
    }
    return (
        <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderRadius: '20px',
                boxShadow: '0 20px 60px rgba(0, 0, 0, 0.3)',
                fontFamily: 'Exo, sans-serif',
                pb: 4,
                overflow: 'hidden',
            }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '100%',
                height: '200px',
                background: 'url(https://picsum.photos/1600/400)',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: 3,
                    pb: 1,
                }}>
                    <Avatar
                        src={`https://i.pravatar.cc/150?u=${user?.sub || 'guest'}`}
                        sx={{
                            width: '65px',
                            height: '65px',
                            border: '4px solid #800080',
                            mr: 3,
                        }}
                    />
                    <Typography variant="h4"
                        sx={{
                            color: '#ffd900d3',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                            fontSize: '2rem',
                        }}
                    >
                        {userProfile?.username}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ mx: 4, mb: 4, p: 4 }} >
                <Grid2 container spacing={3}>
                    <Grid2 item size={{ xs: 12, md: 4 }}>
                        <StatsPaper elevation={3}>
                            <EmojiEventsIcon
                                sx={{
                                    color: '#800080',
                                    fontSize: '60px',
                                    mb: '15px'
                                }}
                            />
                            <Typography variant="h6">{t('Batailles gagnées')}</Typography>
                            <Typography variant="h4">{(userProfile && userProfile.wonBattles) ?? 0}</Typography>
                        </StatsPaper>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, md: 4 }}>
                        <StatsPaper elevation={3}>
                            <MilitaryTechIcon
                                sx={{
                                    color: '#800080',
                                    fontSize: '60px',
                                    mb: '15px'
                                }}
                            />
                            <Typography variant="h6">{t('Trophées')}</Typography>
                            <Typography variant="h4">{(userProfile && userProfile.trophies) ?? 0}</Typography>
                        </StatsPaper>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, md: 4 }}>
                        <StatsPaper elevation={3}>
                            <EmojiEventsIcon
                                sx={{
                                    color: '#800080',
                                    fontSize: '60px',
                                    mb: '15px'
                                }}
                            />
                            <Typography variant="h6">{t('Highlights')}</Typography>
                            <Typography variant="h4">{(userProfile && userProfile.highlights) ?? 0}</Typography>
                        </StatsPaper>
                    </Grid2>
                </Grid2>
            </Box>

            <Divider sx={{ m: 4, background: '#800080' }} />

            <Box>
                <Typography variant="h5"
                    sx={{
                        mb: 4,
                        color: '#FFD700',
                        fontSize: '2rem',
                        pl: '30px'
                    }}
                >
                    {"Highlights"}
                </Typography>
            </Box>

            <Grid2 container spacing={3}
                sx={{
                    flexGrow: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {userHighlights.length > 0 && userHighlights.map((highlight, index) => (
                    <SingleHighlight key={`my-highligh-${highlight.title}-${index}`} video={highlight} />
                ))}
            </Grid2>
        </Box>
    );
}
