import { Chip, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const listLanguages = [
  'en',
  'fr',
  'pt'
]

export default function LangSelector() {
  const { i18n }  = useTranslation();

  const [lngMenu, setLngMenu] = useState(null)

  const handleOpenLngMenu = (event) => {
    setLngMenu(event.currentTarget);
  }

  const handleCloseLngMenu = () => {
    setLngMenu(null);
  }

  return (
    <>
      <Chip
        variant="outlined"
        size="small"
        label={i18n.language.toUpperCase()}
        onClick={handleOpenLngMenu}
        sx={{
          color: 'white',
          paddingRight: '0px',
          paddingLeft: '0px',
        }}
      />
      <Menu
        sx={{
          mt: '45px',
          '& .MuiPaper-root': {
            backgroundColor: '#19153b',
            color: 'white',
            border : '1px solid black',
          }
        }}
        id="language-menu"
        anchorEl={lngMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(lngMenu)}
        onClose={handleCloseLngMenu}
      >
        {
          listLanguages.map((lng) => (
              <MenuItem
                key={lng}
                onClick= {() => {
                  i18n.changeLanguage(lng);
                  handleCloseLngMenu();
                }}
              >
                <Typography sx={{ textAlign: 'center' }}>{lng.toUpperCase()}</Typography>
              </MenuItem>
          ))
        }

      </Menu>
    </>
  );
}
