import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../context/keycloakContext";
import HighlightBattle from '../components/Feed/HighlightBattle';
import { Api } from '../services/Api';

export default function HomePage() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  
  const [videoPairs, setVideoPairs] = useState([]);


  // getMultipleRandomVideos et afficher plusieurs composants HighlightBattle.jsx 
  // en fonction des paires de vidéos récupérées.
  useEffect(() => {
    const fetchVideoPairs = async () => {
      try {
        const pairs = await Api.getMultipleRandomVideos(5); // Récupère 5 paires de vidéos
        setVideoPairs(pairs);
      } catch (error) {
        console.error('Erreur lors de la récupération des paires de vidéos aléatoires', error);
      }
    };

    fetchVideoPairs();
  }, []);

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {
        isAuthenticated ?
          <h2>{tCommon('Ah te voilà')} {user?.name}</h2>
        :
          <h2>Bienvenue Gast</h2>
      }
      
      <div className="home-page">
        <h1>Fil d'Actualité</h1>
        {videoPairs.length > 0 ? (
          videoPairs.map((pair, index) => (
            <HighlightBattle key={`battle_${index + 0}`} videoPair={pair} />
          ))
        ) : (
          <p>Chargement des vidéos...</p>
        )}
      </div>
    </div>
  );
}
