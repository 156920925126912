import {
  AppBar,
  Toolbar,
  Typography
} from "@mui/material"
import AvatarMenu from "./AvatarMenu"
import { useKeycloak } from "../context/keycloakContext";
import { Search } from '@mui/icons-material';
import LangSelector from "./langSelector";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { SearchButton } from "./CustomComponents";
import SearchBackDrop from "./SearchBackDrop";
import { useState } from "react";


const settings = [
  {
    text: 'settings',
    route: '/settings'
  },
  {
    text: 'profile',
    route: '/profile'
  }
]

export default function Header() {
  const { isAuthenticated, kc } = useKeycloak();
  const { t: tCommon } = useTranslation('app');

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  }

  return (
    <>
      <SearchBackDrop open={open} handleToggle={handleToggle} />
      <AppBar
        elevation={1}
        position="static"
        sx={{
          background: 'radial-gradient(ellipse at center 150%, rgba(173,83,137,0.5) 0%, transparent 70%)',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            paddingRight: '10px',
            paddingLeft: '10px',
            justifyContent: 'space-between',
          }}
        >
          {/* button recherche */}
          <SearchButton variant="outlined"
            onClick={handleToggle}
            sx={{
              height: '35px',
              gap: 1,
              marginRight: 'auto',
              marginLeft: 'auto',
              background: 'linear-gradient(135deg, rgba(15, 85, 232, 0.1) 0%, rgba(157, 223, 243, 0.1) 100%)',
              color: 'white',
              borderRadius: '30px',
              borderWidth: '1px',
              border: '1px solid #800080',
              "&:hover": {
                transform: 'translateY(-1px)',
                boxShadow: '0 5px 60px rgba(104, 8, 213, 0.5)'
              }
            }}
          >
            <Typography variant="h7" sx={{color: "white", fontFamily: 'Exo, sans-serif'}}>
              {tCommon('search')}
            </Typography>
            <Search sx={{color: "white", float: 'right'}} />
          </SearchButton>

          {
            isAuthenticated ?
              <AvatarMenu
                settings={settings}
              />
            :
            <Button
              type={"primary"}
              onClick={() => {
                kc?.login({
                    redirectUri: window.location.origin
                  })
              }}
            >
              {tCommon('login')}
            </Button>
          }

          <LangSelector />
        </Toolbar>
      </AppBar>
    </>
    
  );
}
