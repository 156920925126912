import { RouterProvider } from 'react-router-dom';
import getRouter from './Router';
import { useKeycloak } from './context/keycloakContext';


export default function MyApp() {
  const kc = useKeycloak();
  const router = getRouter(kc.isAuthenticated, kc.user);

  return <RouterProvider router={router}/>
}
