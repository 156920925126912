import { useTranslation } from "react-i18next"

export default function Forbidden() {
  const { t } = useTranslation("errors")
  return (
    <h1>
      {t("403")}
    </h1>
  )
}
