import { createBrowserRouter, Outlet } from "react-router-dom"
import UploadPage from "./Pages/uploadPage/UploadPage"
import PrivateRoute from "./components/PrivateRoute"
import Logout from "./components/Logout"
import SideBar from "./components/SideBar"
import Header from "./components/Header"
import HomePage from "./Pages/HomePage"
import { ErrorPage } from "./Pages/errors/ErroPage"
import { Versus } from "./Pages/VersusPage/Versus"
import { ProfilPage } from "./Pages/ProfiPage/PageProfil"

export default function getRouter() {
  return createBrowserRouter([
    {
      path: '/',
      errorElement: <ErrorPage />,
      element: 
        <main>
          <SideBar />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              width: '100%',
              height: '100%',
            }}
          >
            <Header />
            <div
              id="main-content"
              style={{
                padding: '20px',
                height: '100%',
                overflow: 'auto',
              }}
            >
              <Outlet  />
            </div>
          </div>
        </main>
      ,
      children: [
        {
          path: '/',
          element: <HomePage />
        },
        {
          path: '/upload',
          element:
          <PrivateRoute>
            <UploadPage />
          </PrivateRoute>
        },
        {
          path: '/login',
          element: <PrivateRoute />
        },
        {
          path: '/logout',
          element: <Logout />
        },
        {
          path: '/ultra',
          element: 
            <PrivateRoute>
              <h1>ULTRA</h1>
            </PrivateRoute>
        },
        {
          path: '/versus', 
          element: 
            <PrivateRoute>
              <Versus/>
            </PrivateRoute>
        }, 
        {
          // path: '/my-ligher-profile',
          path: '/lighter-profile/:username',
          element:
            <PrivateRoute>
              <ProfilPage/>
            </PrivateRoute>
        }
      ]
    }
  ]);
}
