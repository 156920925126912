import styles from './video-layout.module.css';
import PropTypes from 'prop-types';

import {
  Captions,
  ChapterTitle,
  Controls,
  Gesture
} from '@vidstack/react';

import * as Buttons from './shared/buttons';
import * as Menus from './shared/menus';
import * as Sliders from './shared/sliders';
import { TimeGroup } from './shared/time-group';


const AllowedTypes = [
  'fullControls',
  'noControls',
  'minimalControls',
]

VideoLayout.propTypes = {
  layoutType: PropTypes.oneOf(AllowedTypes),
  thumbnails: PropTypes.array,
};


export default function VideoLayout({ layoutType, thumbnails }) {

  switch (layoutType) {
    case 'fullControls':
      return <FullControlsLayout thumbnails={thumbnails} />;
    case 'noControls':
      return <NoControlsLayout />;
    case 'minimalControls':
      return <MinimalControlsLayout />;
    default:
      return <FullControlsLayout thumbnails={thumbnails} />;
  }
}

function Gestures() {
  return (
    <>
      <Gesture className={styles.gesture} event="pointerup" action="toggle:paused" />
      <Gesture className={styles.gesture} event="dblpointerup" action="toggle:fullscreen" />
      <Gesture className={styles.gesture} event="pointerup" action="toggle:controls" />
      <Gesture className={styles.gesture} event="dblpointerup" action="seek:-10" />
      <Gesture className={styles.gesture} event="dblpointerup" action="seek:10" />
    </>
  );
}

FullControlsLayout.propTypes = {
  thumbnails: PropTypes.array
};

function FullControlsLayout({ thumbnails }) {
  return (
    <>
      <Gestures />
      <Captions className={`${styles.captions} vds-captions`} />
      <Controls.Root className={`${styles.controls} vds-controls`}>
        <div className="vds-controls-spacer" />
        <Controls.Group className={`${styles.controlsGroup} vds-controls-group`}>
          <Sliders.Time thumbnails={thumbnails} />
        </Controls.Group>
        <Controls.Group className={`${styles.controlsGroup} vds-controls-group`}>
          <Buttons.Play tooltipPlacement="top start" />
          <Buttons.Mute tooltipPlacement="top" />
          <Sliders.Volume />
          <TimeGroup />
          <ChapterTitle className="vds-chapter-title" />
          <div className="vds-controls-spacer" />
          <Buttons.Caption tooltipPlacement="top" />
          {/* <Menus.Settings placement="top end" tooltipPlacement="top" /> */}
          <Buttons.Pip tooltipPlacement="top" />
          <Buttons.Fullscreen tooltipPlacement="top end" />
        </Controls.Group>
      </Controls.Root>
    </>
  )
}

function NoControlsLayout() {
  return (
    <>
      <Gestures />
      <Captions className={`${styles.captions} vds-captions`} />
    </>
  )
}

function MinimalControlsLayout() {
  return (
    <>
      <Gestures />
      <Captions className={`${styles.captions} vds-captions`} />
      <Controls.Root className={`${styles.controls} vds-controls`}>
        <div className="vds-controls-spacer" />'
        
        <Controls.Group className={`${styles.controlsGroup} vds-controls-group`}>
          <Buttons.Play tooltipPlacement="top start" />
          <div className="vds-controls-spacer" />
          <Buttons.Mute tooltipPlacement="top" />
          <TimeGroup />
        </Controls.Group>
      </Controls.Root>
    </>
  )
}
