import { Search } from "@mui/icons-material"
import {
  Backdrop,
  Box,
  CircularProgress,
  ClickAwayListener,
  List,
  ListItemText,
  Paper,
  TextField
} from "@mui/material"
import PropTypes from "prop-types"
import { CustomListSubheader, SearchListButton } from "./CustomComponents"
import { useState } from "react"
import { Api } from "../services/Api"
import { NavLink } from "react-router-dom"


export default function SearchBackDrop({ open, handleToggle }) {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [ligthers, setLighters] = useState([]);

  const fetchLighters = async (search) => {
    Api.searchUsers(search)
      .then((data) => {
        setLighters(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleInSearch = async (search) => {
    setLoading(true);
    await fetchLighters(search);
  }

  return (
    <Backdrop
      open={open}
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      {
        open &&
        <ClickAwayListener
          onClickAway={
            open?
              () => {
                handleToggle();
                setSearch('');
                setLighters([]);
                setLoading(false);
              }
            :
              {}
          }
        >
          <Paper
            elevation={3}
            sx={{
              width: "35%",
              height: "60%",
              backgroundColor: "red",
              zIndex: 1,
              transform: "translate(0, -20%)",
              background: "linear-gradient(135deg, #201c47 0%, #19153b 50%)",
              borderRadius: 4,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              border: "1px solid #5740fe"
            }}
          >
            {/* Recherche */}
            <Box
              sx={{
                width: "100%",
                flex: 1,
                borderBottom: "1px solid #5740fe",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Search sx={{
                color: '#800080',
                height: '100%',
                fontSize: '2rem',
                mx: 2,
              }}/>
              <TextField
                autoFocus
                hiddenLabel
                fullWidth
                placeholder="Rechercher"
                variant="standard"
                slotProps={{
                  input: {
                    disableUnderline: true,
                  }
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'white',
                    height: '100%',
          
                  },
                  '& .MuiInputBase-input': {
                    height: '100%',
                  },
                }}
                onChange={(e) => handleInSearch(e.target.value)}
              />
            </Box>
            {/* Resultat */}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                flex: 7,
              }}
            >
              {
                loading ?
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress sx={{ color: "#800080" }} />
                  </Box>
                :
                  <Results
                    label={"Lighters"}
                    data={ligthers}
                    handleToggle={
                      () => {
                        handleToggle();
                        setSearch('');
                        setLighters([]);
                        setLoading(false);
                      }
                    }
                  />
              }
            </Box>
            {/* footer */}
            <Box
              sx={{
                width: "100%",
                flex: 1,
                borderTop: "1px solid #5740fe",
              }}
            >
            </Box>
          </Paper>
        </ClickAwayListener>
      }

    </Backdrop>
  );
}

function Results({ label, data, handleToggle }) {
  return (
    <>
      {
        data.length === 0 ?
          null
        :
          <List
            subheader={
              <CustomListSubheader sx={{ ml: 1, mt: 1, height: "30px" }} component={'div'}>
                {label}
              </CustomListSubheader>
            }
            sx={{
              mx: 1,
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {
              data.map((item, index) => (
                <NavLink key={index+item.username} to={`/lighter-profile/${item.username}`} style={{textDecoration: 'none', color: 'white'}}>
                  <SearchListButton onClick={handleToggle}>
                    <ListItemText primary={item.username} />
                  </SearchListButton>
                </NavLink>
              ))
            }
          </List>
      }
    </>
  )
}

SearchBackDrop.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
}

Results.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  handleToggle: PropTypes.func.isRequired,
}
