import {
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { CustomSelect, CustomTextField } from "../../components/CustomComponents";
import { useTranslation } from "react-i18next";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Api } from "../../services/Api";


const UploadForm = forwardRef((_, _ref) => {
  const { t } = useTranslation("UploadPage")

  // Data of select values
  const [gameLicenses, setGameLicenses] = useState([])

  // states of form fields
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [gameLicense, setGameLicense] = useState("")

  // error stated of form fields
  const [titleError, setTitleError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [gameLicenseError, setGameLicenseError] = useState(false)

  useImperativeHandle(_ref, () => ({
    title: title,
    description: description,
    gameLicense: gameLicense,
    verifyFields: () => {
      let verified = true
      if (title === "") {
        setTitleError(true)
        verified =  false
      }
      if (description === "") {
        setDescriptionError(true)
        verified =  false
      }
      if (gameLicense === "") {
        setGameLicenseError(true)
        verified =  false
      }
      return verified
    },
    resetStates: () => {
      setTitle("")
      setDescription("")
      setGameLicense("")
    },
  }), [title, description, gameLicense])

  useEffect(() => {
    fetchLicenses()
  }, [])

  const fetchLicenses = async () => {
    // Fetch game licenses
    await Api.getGameLicenses()
      .then((response) => {
        setGameLicenses(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Grid2
      container
      spacing={2}
      sx={{
        width: "90%",
        my: 4,
      }}
    >
      {/* TITLE FIELD */}
      <Grid2
        size={{
          sm: 12,
          md: 6
        }}
      >
        <CustomTextField
          variant="outlined"
          id="video-title"
          label={t("video title")}
          fullWidth
          onChange={(e) => {
            setTitle(e.target.value)
            setTitleError(false)
          }}
          error={titleError}
          helperText={titleError? t("title is required") : ""}
        />
      </Grid2>

      {/* SELECT FIELD */}
      <Grid2
        size={{
          sm: 12,
          md: 6
        }}
      >
        <FormControl
          fullWidth
          error={gameLicenseError}
        >
          <InputLabel
            id="video-category-label"
            sx={{
              color: "grey",
              fontWeight: "bold",
              '&.Mui-focused': {
                color: 'grey', // Couleur du label quand le Select est en focus
              },
            }}
          >
            {t("video game license")}
          </InputLabel>
          <CustomSelect
            labelId="video-category-label"
            variant="outlined"
            id="video-category"
            label={t("video game license")}
            MenuProps={{
              PaperProps: {
                elevation: 10,
                style: {
                  color: "white",
                  backgroundColor: "#19153b",
                  // border: "1px solid grey",
                }
              }
            }}
            onChange={(e) => {
              setGameLicense(e.target.value)
              setGameLicenseError(false)
            }}
            onClick={() => {
              if (gameLicenses.length === 0) {
                fetchLicenses()
              }
            }}
          >
            {
              gameLicenses.length > 0 && gameLicenses.map((gameLicense, index) => (
                <MenuItem
                  key={index+gameLicense}
                  value={gameLicense.gameLicenseId}
                >
                  {gameLicense.licenseName}
                </MenuItem>
              ))
            }
          </CustomSelect>
          <FormHelperText>{gameLicenseError? t("game license is required") : ""}</FormHelperText>
        </FormControl>
      </Grid2>

      {/* DESCRIPTION FIELD */}
      <Grid2
        size={12}
      >
        <CustomTextField
          variant="outlined"
          id="video-title"
          label={t("video description")}
          fullWidth
          multiline
          rows={4}
          onChange={(e) => {
            setDescription(e.target.value)
            setDescriptionError(false)
          }}
          error={descriptionError}
          helperText={descriptionError? t("description is required") : ""}
        />
      </Grid2>
       
    </Grid2>
  )
})

export default React.memo(UploadForm);
