import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});

// Add axios interceptor to keycloak add token to request headers
export const addAxiosKcInterceptor = (kc) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (kc.token) {
        config.headers.Authorization = `Bearer ${kc.token}`
      }
      return config
    },
    (error) => {
      console.error("Error in axios interceptor: ", error)
      return Promise.reject(error)
    }
  )
}
