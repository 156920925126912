import {
  List,
} from "@mui/material";
import {
  FaceOutlined,
  GradeOutlined,
  HomeOutlined,
  InsightsOutlined,
  Person4Outlined, 
  QuestionAnswerOutlined,
  SportsEsportsOutlined,
  VideoLibraryOutlined
} from '@mui/icons-material';
import SideBarItem from "./SideBarItem";
import { useKeycloak } from '../context/keycloakContext';
import { useTranslation } from 'react-i18next';
import { CustomListSubheader, SideBarPaper } from "./CustomComponents";

const defaultList = [
  {
    Icon: HomeOutlined,
    text: "home",
    route: '/'
  },
  {
    Icon: SportsEsportsOutlined,
    text: "versus fil",
    route: '/versus'
  },
  {
    Icon: InsightsOutlined,
    text: "ultra lights",
    route: '/ultra'
  }
];

export default function SideBar() {
  const { isAuthenticated, user } = useKeycloak();
  const { t: tCommon } = useTranslation('app');
  const { t } = useTranslation('SideBar');

  const mySpaceList = [
    {
      Icon: Person4Outlined,
      text: "profil lighter",
      route: `/lighter-profile/${user?.preferred_username}`
    },
    {
      Icon: VideoLibraryOutlined,
      text: "upload",
      route: '/upload'
    },
    {
      Icon: QuestionAnswerOutlined,
      text: "messages",
      route: '/chats'
    },{
      Icon: GradeOutlined,
      text: "favorites",
      route: '/saved-high-lights'
    }
  ];

  return (
    <SideBarPaper elevation={6}>
      <div className="SideBar-Header">
        <h1>{tCommon('name')}</h1>
      </div>
      {/* Default list */}
      <List component={'nav'} >
        {defaultList.map((item, index) => (
          <SideBarItem
            key={index+item.text}
            Icon={item.Icon}
            text={t(item.text)}
            route={item.route}
          />
        ))}
      </List>
      {/* List my space */}
      {
        isAuthenticated ?
          <>
            <List
              subheader={
                <CustomListSubheader component="div">
                  {t('my space').toUpperCase()}
                </CustomListSubheader>
              }
              component={'nav'}
            >
              {mySpaceList.map((item, index) => (
                <SideBarItem
                  key={index+item.text}
                  Icon={item.Icon}
                  text={t(item.text)}
                  route={item.route}
                />
              ))}
            </List>
            {/* TODO: Community list ??? */}
            {/* <List
              subheader={
                <CustomListSubheader component="div">
                  {t('community').toUpperCase()}
                </CustomListSubheader>
              }
              component={'nav'}
            >
              <SideBarItem
                Icon={FaceOutlined}
                text={t('User')}
                route={'/user/username'}
              />
            </List> */}
          </>
          
        :
          null
      }

    </SideBarPaper>
  );
}
