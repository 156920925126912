import { useEffect, useState } from "react"
import { isRouteErrorResponse, NavLink, useRouteError } from "react-router-dom"
import NotFound from "./404"
import Unauthorized from "./401"
import Forbidden from "./403"
import Button from "../../components/Button"
import { useTranslation } from "react-i18next"

export function ErrorPage() {
  const [errorElement, setErrorElement] = useState(null)
  const { t } = useTranslation("errors")

  const error = useRouteError()

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      switch (error.status) {
        case 404:
          setErrorElement(<NotFound />)
          break
        case 401:
          setErrorElement(<Unauthorized />)
          break
        case 403:
          setErrorElement(<Forbidden />)
          break
        default:
          setErrorElement(<h1>Something went wrong</h1>)
      }
    }
  }, [error])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        flexDirection: 'column-reverse',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100px',
        }}
      >
        <NavLink
          to="/"
        >
          <Button
            type="primary"
            onClick={() => {}}
          >
            {t("back home")}
          </Button>
        </NavLink>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {errorElement}
      </div>
    </div>
  )
}
