import { useTranslation } from "react-i18next"

export default function Unauthorized() {
  const { t } = useTranslation("errors")
  return (
    <h1>
      {t("401")}
    </h1>
  )
}
