import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: 'en',                      // language to use if translations are missing
    resources: {
      "en": {
        "app": {
          "name": "High Light's",
          "yes": "Yes",
          "no": "No",
          "welcome": "Welcome to High Light's",
          "settings": "Settings",
          "login": "Login",
          "logout": "Logout",
          "search": "Search...",
          "profile": "Profile",
          "home": "Home",
          "upload": "Upload",
        },
        "errors": {
          "back home": "Back to Home",
          '401': '(401) Unauthorized',
          '403': '(403) Forbidden',
          '404': '(404) Not Found',
          '500': '(500) Internal Server Error',
        },
        "SideBar": {
          "home": "Home",
          "my space": "My space",
          "versus fil": "Versus Fil",
          "ultra lights": "Ultra Light's",
          "profil lighter": "My Lighter Profile",
          "upload": "High Light's Upload",
          "messages": "Chats",
          "favorites": "Saved High light's",
          "community": "Community",
        },
        "UploadPage": {
          "upload title": "Upload your High Light",
          "upload video": "Upload",
          "video title": "Title",
          "video game license": "Game License",
          "video description": "Description",
          "search file": "Search file",
          "invalid file type": "Invalid file type",
          "cancel": "Cancel",
          "upload success": "Video uploaded successfully",
          "upload error": "Error during the upload",
          'form not filled': 'Some form fields are not filled',
          'title is required': 'Title is required',
          'description is required': 'Description is required',
          'game license is required': 'Game license is required',
        }
      },
      "fr": {
        "app": {
          "name": "High Light's",
          "yes": "Oui",
          "no": "Non",
          "welcome": "Bienvenue sur High Light's",
          "settings": "Paramètres",
          "login": "Connexion",
          "logout": "Déconnexion",
          "search": "Rechercher...",
          "profile": "Profil",
          "home": "Accueil",
          "upload": "Télécharger",
          "versus fil": "Versus Fil",
          "ultra lights": "Ultra Light's",
        },
        "errors": {
          "back home": "Retour à l'accueil",
          '401': '(401) Pas autorisé',
          '403': '(403) Interdit',
          '404': '(404) Cette page n\'existe pas',
          '500': '(500) Erreur interne du serveur',
        },
        "SideBar": {
          "home": "Accueil",
          "my space": "Mon espace",
          "versus fil": "Versus Fil",
          "ultra lights": "Ultra Light's",
          "profil lighter": "Mon Profil Lighter",
          "upload": "Upload High Light's",
          "messages": "Messages",
          "favorites": "High Light's sauvegardés",
          "community": "Communauté",
        },
        "UploadPage": {
          "upload title": "Téléchargez votre High Light",
          "upload video": "Télécharger",
          "video title": "Titre",
          "video game license": "Licence du jeu",
          "video description": "Description",
          "search file": "Rechercher fichier",
          "invalid file type": "Type de fichier invalide",
          "cancel": "Annuler",
          "upload success": "Vidéo téléchargée avec succès",
          "upload error": "Erreur lors du téléchargement",
          'form not filled': 'Certains champs du formulaire ne sont pas remplis',
          'title is required': 'Titre est requis',
          'description is required': 'Description est requise',
          'game license is required': 'Licence du jeu est requise',
        }
      },
      "pt": {
        "app": {
          "name": "High Light's",
          "yes": "Sim",
          "no": "Não",
          "welcome": "Bem-vindo ao High Light's",
          "settings": "Configurações",
          "login": "Entrar",
          "logout": "Sair",
          "search": "Pesquisar...",
          "profile": "Perfil",
          "home": "Início",
          "upload": "Carregar",
        },
        "errors": {
          "back home": "Voltar para o início",
          '401': '(401) Não autorizado',
          '403': '(403) Proibido',
          '404': '(404) Página não encontrada',
          '500': '(500) Erro interno do servidor',
        },
        "SideBar": {
          "home": "Início",
          "my space": "Meu espaço",
          "versus fil": "Versus Fil",
          "ultra lights": "Ultra Light's",
          "profil lighter": "Meu Perfil Lighter",
          "upload": "Carregar High Light's",
          "messages": "Mensagens",
          "favorites": "High Light's salvos",
          "community": "Comunidade",
        },
        "UploadPage": {
          "upload title": "Carregue seu High Light",
          "upload video": "Carregar",
          "video title": "Título",
          "video game license": "Licença do jogo",
          "video description": "Descrição",
          "search file": "Procurar arquivo",
          "invalid file type": "Tipo de arquivo inválido",
          "cancel": "Cancelar",
          "upload success": "Vídeo carregado com sucesso",
          "upload error": "Erro durante o carregamento",
          'form not filled': 'Alguns campos do formulário não estão preenchidos',
          'title is required': 'Título é obrigatório',
          'description is required': 'Descrição é obrigatória',
          'game license is required': 'Licença do jogo é obrigatória',
        }
      }
    },
  })
