import {
  Alert,
  Slide,
  Snackbar
} from "@mui/material";
import PropsTypes from 'prop-types'

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

SnackBarAlert.propTypes = {
  open: PropsTypes.bool.isRequired,
  message: PropsTypes.string.isRequired,
  severity: PropsTypes.string.isRequired,
  onClose: PropsTypes.func.isRequired,
}

export default function SnackBarAlert({ open, message, severity, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={15000}
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
    >
      <Alert
        variant="filled"
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
