import { Button } from "@mui/material";
import { VisuallyHiddenInput } from "./CustomComponents";
import { FindInPage } from "@mui/icons-material";
import Propstype from 'prop-types';

FileInputButton.propTypes = {
  onChange: Propstype.func.isRequired,
  children: Propstype.node.isRequired,
}

export default function FileInputButton({ onChange, children }) {

  return (
    <Button
      variant="contained"
      component="label"
      startIcon={<FindInPage />}
      sx={{
        backgroundColor: '#5740fe',
      }}
    >
      {children}
      <VisuallyHiddenInput
        type="file"
        accept="video/*"
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
      />
    </Button>
  );
}
