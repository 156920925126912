import { Navigate } from "react-router-dom";
import { useKeycloak } from "../context/keycloakContext";
import PropsTypes from 'prop-types'



const PrivateRoute = ({ children }) => {
  const { isAuthenticated, kc } = useKeycloak();

  if (isAuthenticated) {
    return children
  } else if (!kc) {
      kc?.login(
        {
          redirectUri: window.location.origin
        }
      );
  } else {
    return <Navigate
      to={'/'}
      replace={true}
    />
  }
};

export default PrivateRoute;
