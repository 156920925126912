import '@vidstack/react/player/styles/default/theme.css';

import styles from './player.module.css';

import {
  MediaPlayer,
  MediaProvider,
} from '@vidstack/react';
import VideoLayout from './layouts/video-layout';
import PropTypes from 'prop-types';

CustomPlayer.propTypes = {
  layoutType: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default function CustomPlayer({ layoutType, src }) {
  return (
    <MediaPlayer
      className={`${styles.player} media-player`}
      title={"Video Player"}
      playsInline
      src={{
        src: `${src}`,
        type: "video/mp4"
      }}
    >
      <MediaProvider />
      <VideoLayout
        layoutType={layoutType}
      />
    </MediaPlayer>
  );
}
