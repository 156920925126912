import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomPlayer from '../../Player/Player';
import { Box, Typography, Button, Fade, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import styles from './HighlightBattle.module.css';
import { useTranslation } from "react-i18next";

export default function HighlightBattle({ videoPair }) {
  const { t } = useTranslation('app');
  const [voted, setVoted] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleVote = (index) => {
    setVoted(true);
    setSelected(index);
    // Vous pouvez ajouter ici la logique de vote (par exemple, envoyer le vote au backend)
  };

  const VideoBox = ({ video, index }) => (
    <Box className={styles.videoContainer}>
      <CustomPlayer layoutType="fullControls" src={video.url} />
      <Box className={styles.highlightMeta}>
        <Typography variant="h6" fontWeight="bold">{video.title}</Typography>
        <Typography variant="body1">{t('Lighter')}: {video.uploader}</Typography>
        <Typography variant="body1">{t('Jeu')}: {video.game}</Typography>
      </Box>
      <Box className={styles.voteButtonContainer}>
        {!voted ? (
          <Tooltip title={t('Voter pour cette vidéo')} placement="left">
            <Button
              variant="contained"
              onClick={() => handleVote(index)}
              className={styles.voteButton}
              startIcon={<HowToVoteIcon />}
            >
              {t('Voter')}
            </Button>
          </Tooltip>
        ) : (
          selected === index && (
            <Fade in={true}>
              <CheckIcon className={styles.voteIcon} />
            </Fade>
          )
        )}
      </Box>
    </Box>
  );

  return (
    <Box className={styles.highlightBattle}>
      <VideoBox video={videoPair[0]} index={0} />
      <Box className={styles.vsContainer}>
        <Typography className={styles.vsText}>VS</Typography>
        <Box className={styles.divider} />
      </Box>
      <VideoBox video={videoPair[1]} index={1} />
    </Box>
  );
}

HighlightBattle.propTypes = {
  videoPair: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uploader: PropTypes.string.isRequired,
      game: PropTypes.string.isRequired,
    })
  ).isRequired,
};
